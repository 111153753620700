export function Footer() {
	return (
		<footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
			<p className="text-xs text-gray-500 dark:text-gray-400">
				© 2024 Sherringford Co., Ltd
			</p>
			<nav className="sm:ml-auto flex gap-4 sm:gap-6">
				<a
					className="text-xs hover:underline underline-offset-4"
					href="https://x.com/kiricliApp"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src="/x-logo.png"
						alt="キリクリ公式Xアカウントへ"
						className="h-4 w-auto"
					/>
				</a>
				<a
					className="text-xs hover:underline underline-offset-4"
					href="https://www.sherringford.tech"
					target="_blank"
					rel="noreferrer"
				>
					運営会社
				</a>
				<a className="text-xs hover:underline underline-offset-4" href="/terms">
					利用規約
				</a>
				<a
					className="text-xs hover:underline underline-offset-4"
					href="/privacy"
				>
					プライバシーポリシー
				</a>
				<a
					className="text-xs hover:underline underline-offset-4"
					href="https://forms.gle/cgEvjVmXpVHfeb476"
					target="_blank"
					rel="noreferrer"
				>
					お問い合わせ
				</a>
			</nav>
		</footer>
	);
}
