import { SignedIn, SignedOut } from "@clerk/remix";

export function Header() {
	return (
		<header className="px-4 lg:px-6 h-14 flex items-center">
			<a className="flex items-center justify-center" href="/">
				<img
					src="/logo.svg"
					alt="キリクリ（KIRICLI）サービスロゴ"
					className="h-8 w-auto"
				/>
			</a>
			<nav className="ml-auto flex gap-2 sm:gap-6">
				<a
					className="text-sm font-medium hover:underline underline-offset-4"
					href="#about"
				>
					キリクリとは？
				</a>
				<a
					className="text-sm font-medium hover:underline underline-offset-4"
					href="#features"
				>
					機能
				</a>
				<a
					className="text-sm font-medium hover:underline underline-offset-4"
					href="#faq"
				>
					FAQ
				</a>
				<a
					className="text-sm font-medium hover:underline underline-offset-4"
					href="/media"
				>
					お役立ち記事
				</a>
				<SignedIn>
					<a
						className="text-sm font-medium hover:underline underline-offset-4"
						href="/home"
					>
						ホーム
					</a>
				</SignedIn>
				<SignedOut>
					<div className="flex items-center gap-4">
						<a
							className="text-sm font-medium hover:underline underline-offset-4"
							href="/sign-in"
						>
							ログイン
						</a>
						<a
							className="text-sm font-medium hover:underline underline-offset-4"
							href="/sign-up"
						>
							ベータ版登録
						</a>
					</div>
				</SignedOut>
			</nav>
		</header>
	);
}
